import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import styled from "styled-components"
import BgImg from "../components/BgImg"

const GalleryCategoryContainer = styled.div`
  width: 500px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px;
  max-width: 95vw;
  z-index: 10;
  text-align: center;

  h1 {
    color: white;
    line-height: 1;
  }

  .category-img {
    width: 90vw;
    height: 300px;
    max-width: 500px;
  }
`

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      exteriorImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "exterior" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      interiorImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "interior" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      bathroomImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "bathroom" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      kitchenImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "kitchen" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      livingAreaImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "living-area" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      gameRoomImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "game-room" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      specialtyRoomImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "specialty-room" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      outdoorAreaImg: allMarkdownRemark(
        limit: 10
        filter: { frontmatter: { tags: { in: "outdoor-area" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              picture {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Gallery" />
      <Container
        noTitle
        style={{
          background: `#52575a`,
          color: `white`,
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100vw",
        }}
        whitetitle
      >
        <GalleryCategoryContainer>
          <Link to="/gallery/exterior">
            <h1>Exterior</h1>
            <Img
              className="category-img"
              fluid={
                data.exteriorImg.edges[data.exteriorImg.edges.length - 1].node
                  .frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Exterior"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/interior">
            <h1>Interior</h1>
            <Img
              className="category-img"
              fluid={
                data.interiorImg.edges[data.interiorImg.edges.length - 1].node
                  .frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Interior"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/kitchens">
            <h1>Kitchens</h1>
            <Img
              className="category-img"
              fluid={
                data.kitchenImg.edges[data.kitchenImg.edges.length - 1].node
                  .frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Kitchens"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/bathrooms">
            <h1>Bathrooms</h1>
            <Img
              className="category-img"
              fluid={
                data.bathroomImg.edges[data.bathroomImg.edges.length - 1].node
                  .frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Bathrooms"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/living-areas">
            <h1>Living Areas</h1>
            <Img
              className="category-img"
              fluid={
                data.livingAreaImg.edges[data.livingAreaImg.edges.length - 1]
                  .node.frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Living Areas"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/game-rooms">
            <h1>Game Rooms</h1>
            <Img
              className="category-img"
              fluid={
                data.gameRoomImg.edges[data.gameRoomImg.edges.length - 1].node
                  .frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Game Rooms"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/specialty-rooms">
            <h1>Specialty Rooms</h1>
            <Img
              className="category-img"
              fluid={
                data.specialtyRoomImg.edges[
                  data.specialtyRoomImg.edges.length - 1
                ].node.frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Specialty Rooms"
            />
          </Link>
        </GalleryCategoryContainer>
        <GalleryCategoryContainer>
          <Link to="/gallery/outdoor-areas">
            <h1>Outdoor Areas</h1>
            <Img
              className="category-img"
              fluid={
                data.outdoorAreaImg.edges[data.outdoorAreaImg.edges.length - 1]
                  .node.frontmatter.picture.childImageSharp.fluid
              }
              alt="HenryGatewood Home Outdoor Areas"
            />
          </Link>
        </GalleryCategoryContainer>
        <BgImg />
      </Container>
    </Layout>
  )
}

export default GalleryPage
